.project {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .project h1 {
    margin-top: 50px;
    color: #3e497a;
    font-size: 35px;
  }
  .project img {
    width: 700px;
    border-radius: 10px;
  }
  
  .project p {
    font-size: 40px;
    color: #3e497a;
  }
  
  .project svg {
    font-size: 60px;
    color: #3e497a;
  }

  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
      
    }
    .projectItem{
      padding: 0;
    }
    p{
      font-size: 36px;
    }
    .project img
    {
        width: 100%;
    }
    .project p
    {
        font-size: 35px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
    .projectItem{
      padding: 0;
    }
    p{
      font-size: 36px;
    }
    .project img
    {
        width: 100%;
    }
    .project p
    {
        font-size: 35px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      width: 100%;
    }
    .projectItem {
      width: 300px;
      height: 300px;
    }
    .projectItem{
      padding: 0;
    }
    p{
      font-size: 36px;
    }
    .project img
    {
        width: 100%;
    }
    .project p
    {
        font-size: 35px;
    }
  }